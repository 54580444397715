import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { useState, useEffect } from "react"
import { EmptyState } from "https://framer.com/m/Shared-8iGD.js@9bbvaZD8RsNyj0rYVQ4z"

const unitNames = ["years", "months", "days", "hours", "minutes", "seconds"]
const unitSizes = [365, 12, 30, 24, 60, 60]
const finishedIndex = 6

function getDateWithTime(date, hour, minute, second, ampm) {
    var newDate = new Date(date)
    newDate.setHours(hour + (ampm === "PM" ? 12 : 0))
    newDate.setMinutes(minute)
    newDate.setSeconds(second)
    return newDate
}

export default function Countdown(props) {
    const { time, labels, units } = props
    const targetDate = getDateWithTime(
        props.date,
        time.hour,
        time.minute,
        time.second,
        time.time
    )
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft())
        }, 1000)
        return () => clearTimeout(timer)
    })

    if (isCanvas && !props.date) {
        return (
            <EmptyState
                title="Set a date value."
                subtitle="Use the properties panel on the right to connect a date value."
                maxWidth={500}
            />
        )
    }

    function calculateTimeLeft() {
        let currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 1)
        const difference = targetDate - currentDate

        let timeLeftValue = [0, 0, 0, 0, 0, 0, false]

        if (difference > 0) {
            timeLeftValue = [
                Math.floor(difference / (1000 * 60 * 60 * 24 * 365)), // Years
                Math.floor((difference / (1000 * 60 * 60 * 24 * 30)) % 12), // Months
                Math.floor((difference / (1000 * 60 * 60 * 24)) % 30), // Days
                Math.floor((difference / (1000 * 60 * 60)) % 24), // Hours
                Math.floor((difference / 1000 / 60) % 60), // Minutes
                Math.floor((difference / 1000) % 60), // Seconds
                false,
            ]

            for (let i = 0; i < unitNames.length; i++) {
                if (!units[unitNames[i]] && i < unitNames.length - 1) {
                    timeLeftValue[i + 1] += timeLeftValue[i] * unitSizes[i + 1]
                    timeLeftValue[i] = null
                }
            }

            if (!units.seconds) {
                timeLeftValue[5] = null
            }

            if (!units.zeroUnits) {
                for (let i = 0; i < unitNames.length; i++) {
                    if (timeLeftValue[i] <= 0) {
                        timeLeftValue[i] = null
                    } else {
                        break
                    }
                }
            }
        }

        timeLeftValue[finishedIndex] = difference > 0

        return timeLeftValue
    }

    const labelLocation = labels?.location
    const segmentStyle = labels
        ? {
              display: "flex",
              gap: labels.gap,
              flexDirection: labelLocation == "inline" ? "row" : "column",
              alignItems: "center",
              minWidth: "64px",
          }
        : { minWidth: "64px", display: "flex", alignItems: "center" }
    const labelStyle = labels
        ? {
              color: labels.fontColor,
              margin: 0,
              ...labels.font,
          }
        : {}
    const colonStyle = {
        color: labels?.fontColor,
        margin: 0,
        display: "flex",
        alignItems: "center", // Ensure the colons are vertically centered
        ...labels?.font,
    }
    const labelFirst = labelLocation == "above"

    let segments = []

    for (let i = 0; i < unitNames.length; i++) {
        if (timeLeft[i] != null) {
            const unit = unitNames[i]
            let label = null
            if (labels) {
                let labelText = unit
                if (labels.style == "short") {
                    labelText = unit[0]
                } else if (labels.style == "full" && timeLeft[i] == 1) {
                    labelText = unit.slice(0, -1)
                }

                label = (
                    <p style={labelStyle}>
                        {labelText}
                        {!labelFirst ? "" : ""}
                    </p>
                )
            }

            const unitText = `${timeLeft[i]}`

            segments.push(
                <div style={segmentStyle} key={unit}>
                    {!labelFirst ? unitText : ""}
                    {label}
                    {labelFirst ? unitText : ""}
                </div>
            )

            if (i < unitNames.length - 1 && timeLeft[i + 1] != null) {
                segments.push(
                    <div
                        style={{
                            ...segmentStyle,
                            minWidth: "auto",
                            display: "flex",
                            alignItems: "center",
                        }}
                        key={`colon-${i}`}
                    >
                        <span style={colonStyle}>:</span>
                    </div>
                )
            }
        }
    }

    if (isCanvas && props.finishedStyle == "layer" && !props.finished?.[0]) {
        return (
            <EmptyState
                title="Connect a finished layer."
                subtitle='Use the handle on the right side of the component to connect a layer to display when the countdown finishes, or change the finished type to "Text"'
                maxWidth={500}
            />
        )
    }

    return (
        <>
            {!timeLeft[finishedIndex] ? (
                props.finishedStyle == "text" ? (
                    <p
                        style={{
                            margin: 0,
                            color: props.fontColor,
                            ...props.font,
                        }}
                    >
                        {props.finishedText}
                    </p>
                ) : (
                    props.finished
                )
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: props.font
                            ? props.font.textAlign
                            : "start",
                        gap: props.gap,
                        color: props.fontColor,
                        textTransform: labels ? labels.case : "capitalize",
                        whiteSpace: "pre-wrap",
                        userSelect: props.textSelect ? "auto" : "none",
                        ...props.font,
                    }}
                >
                    {segments}
                </div>
            )}
        </>
    )
}

const labelDefaults = {
    location: "inline",
    style: "full",
    case: "capitalized",
    gap: 4,
    fontColor: "#000",
}

addPropertyControls(Countdown, {
    date: {
        type: ControlType.Date,
    },
    time: {
        type: ControlType.Object,
        controls: {
            hour: {
                type: ControlType.Number,
                defaultValue: 12,
                min: 1,
                max: 12,
                step: 1,
            },
            minute: {
                type: ControlType.Number,
                defaultValue: 0,
                min: 0,
                max: 59,
                step: 1,
            },
            second: {
                type: ControlType.Number,
                defaultValue: 0,
                min: 0,
                max: 59,
                step: 1,
            },
            time: {
                type: ControlType.Enum,
                defaultValue: "PM",
                options: ["AM", "PM"],
                displaySegmentedControl: true,
            },
        },
    },
    units: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            years: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            months: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            days: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            hours: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            minutes: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            seconds: {
                type: ControlType.Boolean,
                defaultValue: true,
            },
            zeroUnits: {
                type: ControlType.Boolean,
                defaultValue: false,
                enabledTitle: "Show",
                disabledTitle: "Hide",
                description:
                    "Hides units left of the greatest non-zero unit. Ex. If there are 2 hours left, then years, months, and days will be hidden.",
            },
        },
    },
    labels: {
        type: ControlType.Object,
        defaultValue: labelDefaults,
        optional: true,
        buttonTitle: "Options",
        controls: {
            location: {
                type: ControlType.Enum,
                defaultValue: labelDefaults.location,
                options: ["inline", "above", "below"],
                optionTitles: ["Inline", "Above", "Below"],
                displaySegmentedControl: true,
                segmentedControlDirection: "vertical",
            },
            style: {
                type: ControlType.Enum,
                defaultValue: labelDefaults.style,
                options: ["full", "short"],
                optionTitles: ["Full", "Short"],
                displaySegmentedControl: true,
            },
            case: {
                type: ControlType.Enum,
                defaultValue: labelDefaults.case,
                options: ["capitalize", "uppercase", "lowercase"],
                optionTitles: ["Capitalized", "UPPERCASE", "lowercase"],
            },
            gap: {
                type: ControlType.Number,
                defaultValue: labelDefaults.gap,
                min: 0,
                step: 1,
            },
            fontColor: {
                type: ControlType.Color,
                optional: true,
                defaultValue: labelDefaults.fontColor,
            },
            font: {
                type: "font",
                controls: "extended",
            },
        },
    },
    separator: {
        type: ControlType.String,
        defaultValue: ", ",
        hidden(props) {
            return props.labels ? props.labels.location !== "inline" : false
        },
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 0,
        min: 0,
        step: 1,
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#000",
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontSize: 16,
            lineHeight: 1,
        },
    },
    textSelect: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    finishedStyle: {
        type: ControlType.Enum,
        defaultValue: "text",
        options: ["text", "layer"],
        optionTitles: ["Text", "Layer"],
        displaySegmentedControl: true,
        title: "Finished",
    },
    finishedText: {
        type: ControlType.String,
        defaultValue: "Countdown finished!",
        title: "Text",
        hidden: (props) => props.finishedStyle !== "text",
    },
    finished: {
        type: ControlType.ComponentInstance,
        description: "Displays when time = 0",
        title: "Layer",
        hidden: (props) => props.finishedStyle !== "layer",
    },
})
